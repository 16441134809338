import React, { useCallback, useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import CardTemplate from "../card/cardTemplate";
import Header from "../header/Header";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import PropTypes from "prop-types";
import Alert from "@cx/ui/Alert";
import { initializeDataLayer, linkClicked } from "../../api/analytics";
import PasswordResetFormErrorArray from "../password-reset-form-error-array/PasswordResetFormErrorArray";
import { mapToTranslatedMessages } from "../password-validation/translationMap";

const AccountRecoveryEmailPasswordResetForm = props => {
  const [loading, toggleLoading] = useState(true);
  const [errorLink, toggleErrorLink] = useState(false);
  const [displayCloseButton, toggleDisplayCloseButton] = useState(true);
  const [showForm, toggleShowForm] = useState(true);
  const [errorMsg, setErrorMsg] = useState(null);
  const [workflowUrl, setWorkflowUrl] = useState("");
  const [launcherUrl, setLauncherUrl] = useState(null);
  const [userId, setUserId] = useState("");
  const [passwordPolicy, setPasswordPolicy] = useState("");
  const [
    passwordChangeBackendErrors,
    setPasswordChangeBackendErrors
  ] = useState([]);
  const [t] = useTranslation();
  const onLinkExpired = useCallback(() => {
    setErrorMsg(t("accountRecoveryEmailPasswordResetForm.linkExpired"));
    toggleErrorLink(true);
    toggleDisplayCloseButton(false);
    toggleShowForm(false);
  }, [t]);

  useEffect(() => {
    initializeDataLayer("");
  }, []);

  useEffect(() => {
    setLauncherUrl(
      new URL(
        "/solutionlauncher?partition=" + props.partition,
        props.apiBaseUrl
      )
    );
    fetch(
      new URL("/account/recovery/email/token", props.apiBaseUrl).toString(),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.coxauto.v1+json",
          Accept: "application/vnd.coxauto.v1+json"
        },
        body: JSON.stringify({
          token: props.token,
          partition: props.partition
        })
      }
    )
      .then(response => {
        if (!response.ok) {
          throw response;
        }
        return response.json();
      })
      .then(response => {
        setWorkflowUrl(response.workflowUrl);
        setUserId(response.authenticatedUserId);
        setPasswordPolicy(response.passwordPolicy);
        toggleLoading(false);
        toggleErrorLink(false);
        setErrorMsg(null);
        linkClicked(
          "Email Account Recovery Workflow Verified",
          "Account Recovery Password Reset Form Loaded",
          "Account Recovery Email Password Reset Form"
        );
      })
      .catch(failedResponse => {
        if (failedResponse.status === 404) {
          onLinkExpired();
        } else {
          setErrorMsg(t("error.generic"));
          toggleErrorLink(false);
          toggleShowForm(false);
          toggleDisplayCloseButton(false);
        }
        toggleLoading(false);
        linkClicked(
          "Email Account Recovery Workflow Not Verified",
          "Account Recovery Error Page Loaded",
          "Account Recovery Email Password Reset Form"
        );
      });
  }, [props.token, props.partition, props.apiBaseUrl, t, onLinkExpired]);

  const onAccountRecoveryPasswordChange = password => {
    // DISALLOWED_STRINGS_FOR_PASSWORDS_FF is set, use the newer code.
    return fetch(
      new URL("/account/recovery/reset-password", props.apiBaseUrl).toString(),
      {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.coxauto.v1+json",
          Accept: "application/vnd.coxauto.v1+json"
        },
        body: JSON.stringify({
          workflowUrl,
          password,
          partition: props.partition
        })
      }
    )
      .then(apiResponse =>
        apiResponse.json().then(json => {
          json.apiResponseOk = apiResponse.ok;
          json.apiResponseStatus = apiResponse.status;
          return json;
        })
      )
      .then(json => {
        if (json.apiResponseOk) {
          // happy path
          setWorkflowUrl(json.workflowUrl);
          setErrorMsg(null);
          toggleErrorLink(false);
          return json;
        } else {
          // failure path
          throw json;
        }
      })
      .catch(failedJson => {
        if (
          failedJson.apiResponseStatus &&
          (failedJson.apiResponseStatus === 400 ||
            failedJson.apiResponseStatus === 422)
        ) {
          // Display the password error messages.
          setPasswordChangeBackendErrors(
            mapToTranslatedMessages(failedJson.passwordErrorCodes)
          );
        } else if (failedJson.apiResponseStatus === 404) {
          onLinkExpired();
        } else {
          setErrorMsg(t("error.generic"));
          toggleErrorLink(false);
        }
        return false;
      });
  };

  const submitPassword = () => {
    window.location.assign(launcherUrl.href);
  };

  if (loading) {
    return (
      <div>
        <Header />
        <div className={"app-content"}>
          <div className="cardContainer" id="passwordResetEmailContainer">
            <CardTemplate
              title=<Trans i18nKey="passwordResetForm.title" />
              body={
                <div>
                  <LoadingIndicator
                    htmlId="LoadingIndicatorLarge"
                    size="large"
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <Header />
        <div className={"app-content"}>
          {errorMsg && (
            <Alert
              htmlId="errorAlert"
              type="danger"
              displayCloseButton={displayCloseButton}
              onCloseClick={() => setErrorMsg(null)}
            >
              {errorMsg}
              {errorLink && (
                <a href={launcherUrl}>
                  <Trans i18nKey="accountRecoveryEmailPasswordResetForm.login" />
                  .
                </a>
              )}
            </Alert>
          )}
          {showForm && (
            <div className="cardContainer" id="passwordResetEmailContainer">
              <CardTemplate
                title=<Trans i18nKey="passwordResetForm.title" />
                body={
                  <div>
                    <div id="pageWrapper">
                      <PasswordResetFormErrorArray
                        username={userId}
                        onSubmitPassword={onAccountRecoveryPasswordChange}
                        solutionDisplayName={t(
                          "accountRecoveryEmailPasswordResetForm.solutionLauncher"
                        )}
                        signinCallback={submitPassword}
                        reasonType={"email"}
                        passwordPolicy={passwordPolicy}
                        passwordChangeBackendErrors={
                          passwordChangeBackendErrors
                        }
                      />
                    </div>
                  </div>
                }
              />
            </div>
          )}
        </div>
      </div>
    );
  }
};

AccountRecoveryEmailPasswordResetForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  partition: PropTypes.string.isRequired,
  token: PropTypes.string.isRequired
};

export default AccountRecoveryEmailPasswordResetForm;
