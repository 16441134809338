import React from "react";
import Button from "@cx/ui/Button";
import Popover from "@cx/ui/Popover";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import TruncateText from "@cx/ui/TruncateText";
import PropTypes from "prop-types";
import { Trans, Translation } from "react-i18next";
import "./userForm.scss";
import { linkClicked } from "../../api/analytics";
import LinkButton from "../link-button/linkButton";

const PAGE_USERNAME = "usernamePage";
const PAGE_PASSWORD = "passwordPage";

class UserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.username || "",
      password: "",
      loading: false,
      showAsText: false
    };

    this.submitForm = this.submitForm.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onKeyDown = this.onKeyDown.bind(this);
    this.onUsernameKeyDown = this.onUsernameKeyDown.bind(this);
  }

  componentDidMount() {
    // hack to force the initial update
    this.componentDidUpdate({ page: "" }, null, null);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Handle the focusing when switching pages
    if (prevProps.page !== this.props.page) {
      switch (this.props.page) {
        case PAGE_USERNAME:
          document.getElementById("username").focus();
          break;

        case PAGE_PASSWORD:
          document.getElementById("password").focus();
          break;

        default:
          // If we didn't just steal focus, let's make sure to remove focus if the element still on page
          if (
            prevProps.page === PAGE_USERNAME &&
            document.getElementById("username")
          ) {
            document.getElementById("username").blur();
          }

          if (
            prevProps.page === PAGE_PASSWORD &&
            document.getElementById("password")
          ) {
            document.getElementById("password").blur();
          }

          break;
      }
    }
  }

  // IE11 requires the form to be programatically submitted if we want the password manager to detect the username/password.
  submitForm() {
    // Normally we would prefer to use `document.getElementById("login").submit();` but when this is called, the htmp form
    // specification states that it should bypass the handlers, so we will click on the submit button instead
    // see https://stackoverflow.com/a/19847255 for quick writeup of the problem
    document.getElementById("signIn").click();
  }

  handleFormSubmit(e) {
    // Let's stop the form from submitting to the post endpoint
    e.preventDefault();

    switch (this.props.page) {
      case PAGE_USERNAME:
        linkClicked(
          "Next Button",
          "Disambiguation/Password form",
          "Username Form"
        );
        this.props.onSubmitUsername(this.state.username);
        break;

      case PAGE_PASSWORD:
        if (this.state.loading) {
          return false;
        }
        linkClicked(
          "Submit Password Button",
          "Authenticate User",
          "Password Form"
        );
        this.setState({ loading: true });
        this.props.onSubmitPassword(this.state.password).then(success => {
          this.setState({ loading: success });
          if (document.getElementById("password") && !success) {
            document.getElementById("password").value = "";
          }
        });

        return false;

      default:
        break;
    }
  }

  onUsernameKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.submitForm();
    } else if (
      [
        // There seems to be a synthetic "Unidentified" event when password managers run.
        // we have not found a definitive spec for this behavior, this was discovered through observation.
        "Unidentified",

        // Allow tabbing through the fields
        "Tab",

        // Common key presses that don't change the value of the text, we probably don't need these but figured
        // we will cover the most obvious movement and meta keys, not including F1 keys etc.
        "Shift",
        "Control",
        "Meta",
        "Alt",
        "ArrowLeft",
        "ArrowRight",
        "ArrowDown",
        "ArrowUp",
        "Escape",
        "Home",
        "End",
        "PageUp",
        "PageDown"
      ].indexOf(e.key) === -1
    ) {
      this.setState({ password: "" });
      document.getElementById("password").value = "";
    }
  }

  onKeyDown(e) {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      this.submitForm();
    }
  }

  onForgotButtonClick = e => {
    e.preventDefault();
    linkClicked(
      "Forgot Username Button",
      "Username Recovery form",
      "Username Form"
    );
    this.props.onForgotUsernameClick(this.state.username);
  };

  onClickForgotPasswordHint = e => {
    e.preventDefault();
    linkClicked(
      "Forgot Password Hint",
      "Show a hint about how to access password recovery",
      "Username Form"
    );
  };

  onClickForgotPassword = e => {
    e.preventDefault();
    const solutionValue = this.props.authProviderDisplayName.includes("Bridge")
      ? "Bridge"
      : "Solution";
    linkClicked(
      "Forgot Password Link",
      "Allow Password Reset for " + solutionValue,
      "Password Form"
    );

    this.props.onForgotPasswordClick();
  };

  onClickUsername = e => {
    e.preventDefault();
    linkClicked(
      "Username Back Button",
      "Return to Username Form",
      "Password Form"
    );
    this.props.onClickUsernameFromPasswordPage();
  };

  onClickAbort = e => {
    const { abortButton } = this.props;
    e.preventDefault();
    linkClicked(
      "Abort Solution Identity Authentication",
      `Firing Return to ${abortButton.text || "Application"} Callback`,
      "Password Form"
    );
    abortButton.onClick();
  };

  onToggleShowPassword = e => {
    e.preventDefault();
    this.setState({ showAsText: !this.state.showAsText });
  };

  recoveryOptions(t) {
    return (
      <div id="recoveryOptions">
        {t("usernameForm.recoveryVerb")}
        <LinkButton
          htmlId="forgotUsernameButton"
          onClick={this.onForgotButtonClick}
        >
          {t("usernameForm.linkForgotUsername")}
        </LinkButton>
        {t("common.conjunctionOr")}
        <Popover
          htmlId="passwordHint"
          className="popover-item"
          popoverContent={
            <div className="popover-text">
              {t("usernameForm.popoverPasswordHint")}
            </div>
          }
          trigger={["click", "outsideClick"]}
          position="bottom"
        >
          <LinkButton
            htmlId="passwordHintLink"
            onClick={this.onClickForgotPasswordHint}
          >
            {t("usernameForm.linkForgotPassword")}
          </LinkButton>
        </Popover>
      </div>
    );
  }

  render() {
    const backToApplicationButton = this.props.abortButton && (
      <Button id="abort" buttonStyle="secondary" onClick={this.onClickAbort}>
        <Trans
          i18nKey={
            this.props.abortButton.text ? "common.backTo" : "common.back"
          }
          values={{
            return: this.props.abortButton.text
          }}
        />
      </Button>
    );

    const navigationButtons = (
      <Translation>
        {t => (
          <div id="user-form-navigation-buttons">
            {backToApplicationButton}
            <button
              id="signIn"
              type="submit"
              className={
                "btn btn-primary " + (this.state.loading ? "btn--loader" : "")
              }
              disabled={
                this.state.loading ||
                (this.props.page === PAGE_PASSWORD &&
                  this.state.password.trim().length === 0) ||
                (this.props.page === PAGE_USERNAME &&
                  this.state.username.trim().length === 0)
              }
            >
              {this.state.loading && (
                <LoadingIndicator
                  htmlId="LoadingIndicatorWhite"
                  size="small"
                  color="white"
                />
              )}
              {this.props.page === PAGE_USERNAME && t("common.next")}
              {this.props.page === PAGE_PASSWORD &&
                t(
                  this.props.currentFlow === "solutionIdentity"
                    ? "solutionIdentityFlow.buttonAttach"
                    : "passwordForm.buttonSignIn"
                )}
            </button>
          </div>
        )}
      </Translation>
    );

    return (
      <Translation>
        {t => (
          <div>
            <form
              id="login"
              action="/signin"
              method="POST"
              onSubmit={this.handleFormSubmit}
            >
              <div
                className={
                  "input-wrapper username__formGroup form-group " +
                  (this.props.page !== PAGE_USERNAME ? "fakeHidden" : "")
                }
                id="username-inputWrapper"
              >
                <label
                  className="control-label"
                  id="username-label"
                  htmlFor="username"
                >
                  {t("usernameForm.textInputLabel")}
                </label>
                <input
                  type="text"
                  id="username"
                  name="username"
                  autoComplete="username"
                  maxLength={100}
                  className="username-textInput form-control"
                  onChange={e => {
                    this.setState({ username: e.target.value });
                  }}
                  // value={this.state.username}
                  defaultValue={this.props.username} // Must be a default value so it can remain an uncontrolled component (for IE password manager)
                  onKeyDown={this.onUsernameKeyDown}
                />
                {this.props.showForgotUsername && this.recoveryOptions(t)}
                <br />
                {this.props.page === PAGE_USERNAME && navigationButtons}
              </div>

              <div
                className={
                  "input-wrapper textInput password__formGroup form-group " +
                  (this.props.page !== PAGE_PASSWORD ? "fakeHidden" : "")
                }
                id="password-inputWrapper"
              >
                <LinkButton htmlId="return-link" onClick={this.onClickUsername}>
                  <TruncateText htmlId={"return-link-text"}>
                    &larr; {this.props.username}
                  </TruncateText>
                </LinkButton>
                <br />
                <br />
                <LinkButton
                  htmlId="revealPassword"
                  onClick={this.onToggleShowPassword}
                >
                  {this.state.showAsText
                    ? t("common.hidePassword", { count: 1 })
                    : t("common.showPassword", { count: 1 })}
                </LinkButton>
                <label
                  className="textInput-label control-label"
                  id="password-label"
                  htmlFor="password"
                >
                  {t("passwordForm.textInputLabel")}
                </label>
                <input
                  className="password-textInput form-control"
                  id="password"
                  type={this.state.showAsText ? "text" : "password"}
                  name="password"
                  autoComplete="current-password"
                  onChange={e => this.setState({ password: e.target.value })}
                  onKeyDown={this.onKeyDown}
                />
                {this.props.showForgotPassword && (
                  <LinkButton
                    htmlId={"forgotPasswordBtn"}
                    onClick={this.onClickForgotPassword}
                  >
                    {t("passwordForm.linkForgotPassword")}
                  </LinkButton>
                )}
                <br />
                {this.props.page === PAGE_PASSWORD && navigationButtons}
              </div>
            </form>
          </div>
        )}
      </Translation>
    );
  }
}

UserForm.propTypes = {
  abortButton: PropTypes.shape({
    onClick: PropTypes.func.isRequired,
    text: PropTypes.string
  }),
  authProviderDisplayName: PropTypes.string.isRequired,
  currentFlow: PropTypes.string,
  featureFlags: PropTypes.object.isRequired,
  onClickUsernameFromPasswordPage: PropTypes.func.isRequired,
  onForgotPasswordClick: PropTypes.func.isRequired,
  onForgotUsernameClick: PropTypes.func.isRequired,
  onSubmitPassword: PropTypes.func.isRequired,
  onSubmitUsername: PropTypes.func.isRequired,
  page: PropTypes.string.isRequired,
  showForgotPassword: PropTypes.bool.isRequired,
  showForgotUsername: PropTypes.bool.isRequired,
  solution: PropTypes.string.isRequired,
  solutionName: PropTypes.string,
  username: PropTypes.string
};

export default UserForm;
