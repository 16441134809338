import React from "react";
import Header from "./header/Header";
import CardTemplate from "./card/cardTemplate";
import PropTypes from "prop-types";
import Alert from "@cx/ui/Alert";
import { Trans } from "react-i18next";
import { initializeDataLayer } from "../api/analytics";
import PasswordResetFormErrorArray from "./password-reset-form-error-array/PasswordResetFormErrorArray";
import { mapToTranslatedMessages } from "./password-validation/translationMap";

class UserElectedPasswordChangeFlow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errorMsg: null,
      passwordChangeBackendErrors: []
    };

    this.changePassword = this.changePassword.bind(this);
    this.returnSuccess = this.returnSuccess.bind(this);
    this.returnFail = this.returnFail.bind(this);
  }

  componentDidMount() {
    initializeDataLayer("");
  }

  changePassword(newPassword, oldPassword) {
    const url = new URL(this.props.apiBaseUrl + "/change-password"),
      body = {
        partition: this.props.partition,
        oldPassword,
        newPassword
      };
    return fetch(url, {
      headers: {
        "Content-Type": "application/vnd.coxauto.sessionchangepassword.v1+json"
      },
      method: "POST",
      body: JSON.stringify(body),
      mode: "cors"
    })
      .then(response => response.json())
      .then(response => {
        if (Object.entries(response).length === 0) {
          this.setState({
            errorMsg: null,
            passwordChangeBackendErrors: []
          });

          // Start redirecting now
          this.returnSuccess();

          // While this is true, return false so we don't show the success page as we are redirecting anyways
          return false;
        } else {
          switch (response.errorCode) {
            case "INVALID_CURRENT_PASSWORD":
              this.setState({
                errorMsg: <Trans i18nKey="error.INVALID_CURRENT_PASSWORD" />
              });
              break;

            case "FAILED_PASSWORD_CHANGE_REQUIREMENTS":
              // Display the password error messages.
              this.setState({
                passwordChangeBackendErrors: mapToTranslatedMessages(
                  response.passwordErrorCodes
                )
              });
              break;

            default:
              throw new Error(
                "Unexpected Error Code from user password change: " +
                  response.errorCode
              );
          }
          return false;
        }
      })
      .catch(err => {
        if (window.NREUM) window.NREUM.noticeError(err);

        this.setState({ errorMsg: <Trans i18nKey="error.generic" /> });
        return false;
      });
  }

  returnSuccess() {
    window.location.href = this.props.successUrl;
  }

  returnFail() {
    window.location.href = this.props.abortUrl;
  }

  render() {
    return (
      <div>
        <Header />
        <div className={"app-content"}>
          {this.state.errorMsg && (
            <Alert
              htmlId="warningAlert"
              type="warning"
              displayCloseButton
              onCloseClick={() => this.setState({ errorMsg: null })}
            >
              {this.state.errorMsg}
            </Alert>
          )}
          <div className="cardContainer" id="signInControlContainer">
            <CardTemplate
              title=<Trans i18nKey="passwordResetForm.title" />
              body={
                <div>
                  <PasswordResetFormErrorArray
                    username={this.props.username}
                    onSubmitPassword={this.changePassword}
                    passwordvalidation={this.state.passwordvalidation}
                    solutionDisplayName={this.props.solutionDisplayName}
                    signinCallback={this.returnSuccess}
                    collectCurrentPassword={true}
                    reasonKey="requestChange"
                    reasonType={"user-elected"}
                    abortButton={
                      <Trans
                        i18nKey="passwordResetForm.return"
                        values={{
                          solutionDisplayName: this.props.solutionDisplayName
                        }}
                      />
                    }
                    abortButtonCallback={this.returnFail}
                    passwordPolicy={this.props.passwordPolicy}
                    passwordChangeBackendErrors={
                      this.state.passwordChangeBackendErrors
                    }
                  />
                </div>
              }
            />
          </div>
        </div>
      </div>
    );
  }
}

UserElectedPasswordChangeFlow.propTypes = {
  abortUrl: PropTypes.string.isRequired,
  apiBaseUrl: PropTypes.string.isRequired,
  featureFlags: PropTypes.object.isRequired,
  partition: PropTypes.string.isRequired,
  passwordPolicy: PropTypes.string,
  solutionDisplayName: PropTypes.string.isRequired,
  successUrl: PropTypes.string.isRequired,
  username: PropTypes.string.isRequired
};

export default UserElectedPasswordChangeFlow;
