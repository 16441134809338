import React, { Suspense } from "react";
import { render } from "react-dom";
import App from "./components/App";
import "./index.scss";
import "./i18n";
import SolutionLauncher from "./components/solutionlauncher-control/SolutionLauncher";
import UserElectedPasswordChangeFlow from "./components/UserElectedPasswordChangeFlow";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { BrowserRouter as Router } from "react-router-dom";
import AccountRecoveryEmailPasswordResetForm from "./components/account-recovery/AccountRecoveryEmailPasswordResetForm";
import { isThirdPartyCookieEnabled } from "./components/isThirdPartyCookieEnabled";

const {
  solutionlauncherlist,
  apibaseurl,
  transactionid,
  solutioninfo,
  solutiondisplayname,
  solutionid,
  forceerrorstate,
  username,
  featureflags,
  publicview,
  successurl,
  aborturl,
  idp,
  partition,
  currentflow,
  emailrecoverytoken,
  passwordpolicy,
  ...params
} = document.getElementById("params").dataset;

let featureFlags;
try {
  featureFlags = featureflags ? JSON.parse(featureflags) : {};
} catch (parseErr) {
  // If for some reason we get feature flags that are not parsable, let's just
  // log the error and continue
  if (window.NREUM) window.NREUM.noticeError(parseErr);
  featureFlags = {};
}

if (
  solutionlauncherlist &&
  (solutionlauncherlist !== "" || solutionlauncherlist !== "null")
) {
  render(
    <SolutionLauncher launcherOptions={solutionlauncherlist} />,
    document.getElementById("root")
  );
} else if (publicview === "changepassword") {
  render(
    <UserElectedPasswordChangeFlow
      username={username}
      solutionDisplayName={solutiondisplayname}
      successUrl={successurl}
      abortUrl={aborturl}
      partition={partition}
      apiBaseUrl={apibaseurl}
      featureFlags={featureFlags}
      passwordPolicy={passwordpolicy}
    />,
    document.getElementById("root")
  );
} else if (publicview === "resetPasswordByEmail") {
  render(
    <AccountRecoveryEmailPasswordResetForm
      partition={partition}
      apiBaseUrl={apibaseurl}
      token={emailrecoverytoken}
    />,
    document.getElementById("root")
  );
} else {
  // This is important for our SPA's as this is used by the PKCE flow to identify that
  // the solution is not currently logged in... As we are a hop from the original request
  // we can't see if the request passed a no-prompt parameter
  try {
    const errorCallback =
      params.redirect_uri +
      "?state=" +
      params.state +
      "&error=access_denied&error_description=interactive+login+prohibited+within+frame";

    // TODO: This is some J.A.S. so a specific solution group can circumvent 3p cookie deprecation.
    //       Readdress this when no longer needed...
    const acceptableIframeDomains =
      featureFlags["ops.acceptable-iframe-domains"] || [];
    if (window.self !== window.top && acceptableIframeDomains.length) {
      // J.A.S. to start hand-shake with top window for loading signin in an iframe:
      window.top.postMessage("ORIGIN_REQUEST", "*");

      // J.A.S. to default to errorCallback if we don't get a response from top window in short period of time:
      const timeoutID = setTimeout(() => {
        window.location = errorCallback;
      }, 500);

      // J.A.S to clear the timer, so signin can be in an iframe:
      window.addEventListener("message", event => {
        if (
          // make sure the topmost window in the window hierarchy is the one that sent the message:
          window.top === event.source &&
          acceptableIframeDomains.find(domain => event.origin.includes(domain))
        ) {
          clearTimeout(timeoutID);
        }
      });
      // don't allow the iframe:
    } else if (window.self !== window.top) {
      window.location = errorCallback;
    }
  } catch (e) {
    // do nothing, this is to just see if we have this use case right now
    if (window.NREUM) window.NREUM.noticeError(e);
  }

  // Remove query params before rendering so users can not bookmark this app in it's current state
  const bookMarkSolution =
    "/?solutionID=" + solutionid + "&clientId=" + params.client_id;

  window.history.replaceState({}, document.title, bookMarkSolution);
  // Render the ui
  render(
    <Suspense fallback={<LoadingIndicator />}>
      <Router>
        <App
          isThirdPartyCookieAllowed={isThirdPartyCookieEnabled}
          idp={idp && JSON.parse(idp)}
          abortUrl={aborturl}
          params={params}
          apiBaseUrl={apibaseurl}
          transactionId={transactionid}
          solutionDisplayName={solutiondisplayname}
          bookMarkSolution={bookMarkSolution}
          solutionId={solutionid}
          solution={solutioninfo}
          forceErrorState={forceerrorstate}
          username={username}
          featureFlags={featureFlags}
          currentFlow={currentflow}
          partition={partition}
        />
      </Router>
    </Suspense>,
    document.getElementById("root")
  );
}
