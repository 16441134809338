import React from "react";
import PropTypes from "prop-types";
import IconError from "@cx/ui/Icons/IconError";

const TextInput = props => {
  return (
    <div>
      <label
        className="textInput-label control-label"
        id={props.htmlId + "-label"}
        htmlFor={props.htmlId}
      >
        {props.label}
      </label>

      <div className={props.error ? "has-error" : ""}>
        <input
          className="form-control password-textInput"
          id={props.htmlId}
          type={props.type}
          name={props.htmlId}
          autoComplete={props.htmlId}
          onChange={props.onChange}
          onKeyDown={props.onKeyDown}
          onBlur={props.onBlur}
        />
      </div>

      {props.error && (
        <div className="has-error help-block__wrapper">
          <IconError className="svgicon icon-error" />
          <span className="help-block">{props.error}</span>
        </div>
      )}
    </div>
  );
};

TextInput.propTypes = {
  error: PropTypes.string,
  htmlId: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onBlur: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onKeyDown: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

export default TextInput;
