import React from "react";
import { linkClicked } from "../../api/analytics";
import "./broadcast-message.scss";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import { getI18n, Trans } from "react-i18next";

const validStyles = ["warning", "danger", "info", "primary", "success"];
const supportedLanguages = ["en", "es", "fr"];

const validateConfig = config => {
  // Check that `style` exists and is one of the supported options
  if (!config.style || !validStyles.includes(config.style)) return false;

  // Check that English body text exists, at minimum
  return config.text && config.text.en && config.text.en.body;
};

const validateLanguage = (config, lang) => {
  for (const key of Object.keys(config.text.en)) {
    if (!config.text[lang] || !config.text[lang][key]) {
      return false;
    }
  }

  return true;
};

const activeMessage = config => {
  const textStyle = config.style === "primary" ? "white" : config.style;

  return (
    <div
      className={`broadcast-message-container bg-${config.style} text-${textStyle}`}
    >
      <div className="broadcast-message">
        {config.text.en.title && (
          <div className="text-uppercase h2">
            <Trans i18nKey="broadcast.title" />
          </div>
        )}

        <Trans i18nKey="broadcast.body" />
        <br />
        {config.buttonUrl && getI18n().exists("broadcast.buttonText") && (
          <Button
            size="sm"
            htmlId="broadcastButton"
            href={config.buttonUrl}
            target="_blank"
            rel="noopener noreferrer"
            className={`btn-${config.style}`}
            onClick={e => {
              linkClicked(
                "Broadcast Message",
                "Navigate to Additional Info Page",
                "Info Header"
              );
            }}
          >
            <Trans i18nKey="broadcast.buttonText" />
          </Button>
        )}
      </div>
    </div>
  );
};

const BroadcastMessage = props => {
  if (props.config && validateConfig(props.config)) {
    for (const lang of supportedLanguages) {
      getI18n().addResourceBundle(
        lang,
        "translation",
        {
          broadcast: validateLanguage(props.config, lang)
            ? props.config.text[lang]
            : {}
        },
        true,
        true
      );
    }
    return activeMessage(props.config);
  } else {
    return <div />;
  }
};

BroadcastMessage.propTypes = {
  config: PropTypes.object.isRequired
};

export default BroadcastMessage;
