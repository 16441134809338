import React from "react";
import PropTypes from "prop-types";
import "./IconStack.scss";

const IconStack = ({ children, className, ...other }) => {
  return (
    <span
      className={`icon-stack${className ? ` ${className}` : ""}`}
      {...other}
    >
      {children}
    </span>
  );
};

IconStack.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
};

export default IconStack;
