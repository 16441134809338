import React from "react";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import IconCircle from "../icons/IconCircle";
import IconStack from "../icons/IconStack";
import IconSms from "@cx/ui/Icons/IconTextsms";
import IconEmail from "@cx/ui/Icons/IconEmail";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMobileScreenButton } from "@fortawesome/free-solid-svg-icons";
import "./accountVerificationOptionsForm.scss";

const AccountVerificationOptionsForm = props => {
  const onClickVerifyButton = (e, factor) => {
    props.onChallengeFactor(factor.id).then(success => {
      const resultMessage = success
        ? `Send an account verification code by ${factor.factorType}`
        : `Failed to send an account verification code by ${factor.factorType}`;
      linkClicked(
        `Verify by ${factor.factorType} button`,
        resultMessage,
        "Account Verification Options Form"
      );
    });
  };
  return (
    <Translation>
      {t => (
        <FormGroup
          className="accountVerificationOptionsForm"
          controlId="accountVerificationOptionsForm"
        >
          <div className="accountVerificationHeader">
            <strong>{t("accountVerificationOptionsForm.body")}</strong>
          </div>
          {props.factors.map(option => {
            const factorType = option.factorType.toLowerCase();
            let IconFactor;
            switch (factorType) {
              case "sms":
                IconFactor = (
                  <IconSms
                    className="stacked-icon circled-icon"
                    isActive={true}
                  />
                );
                break;
              case "email":
                IconFactor = (
                  <IconEmail
                    className="stacked-icon circled-icon"
                    isActive={true}
                  />
                );
                break;
              case "totp":
                IconFactor = (
                  <FontAwesomeIcon
                    className="stacked-icon circled-icon icons-svg fa-lg"
                    icon={faMobileScreenButton}
                  />
                );
                break;
              default:
            }
            return (
              <Card
                key={factorType}
                className={"factor-card factor-card-" + factorType}
              >
                <IconStack>
                  <IconCircle isActive={true} />
                  {IconFactor}
                </IconStack>
                <div className="accountVerificationCardText">
                  {
                    <Trans
                      htmlFor={"verification-option-button-" + factorType}
                      i18nKey={"accountVerificationOptionsForm." + factorType}
                      values={{ param: option.translationParam }}
                      components={{
                        disclaimer: (
                          // Text will be injected by translation so disable lint rule below
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <div className="accountVerificationSmsDisclaimerLabel" />
                        )
                      }}
                    />
                  }
                </div>
                <span className="button-verify">
                  <Button
                    htmlId={"button-verify-by-" + factorType}
                    size="small"
                    onClick={e => onClickVerifyButton(e, option)}
                  >
                    {t("accountVerificationOptionsForm.select")}
                  </Button>
                </span>
              </Card>
            );
          })}
          <div className="accountVerificationHelpText">
            {
              <Trans
                i18nKey={"accountVerificationOptionsForm.help"}
                components={{
                  faqLink: (
                    // Text will be injected by translation so disable lint rule below
                    // eslint-disable-next-line jsx-a11y/anchor-has-content
                    <a
                      href={"https://www.coxautoinc.com/bridgeid"}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={e =>
                        linkClicked(
                          "Help",
                          "Navigate to Help",
                          "Account Verification Options Form"
                        )
                      }
                    />
                  )
                }}
              />
            }
          </div>
        </FormGroup>
      )}
    </Translation>
  );
};

AccountVerificationOptionsForm.propTypes = {
  factors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      factorType: PropTypes.string.isRequired,
      sendsChallenge: PropTypes.bool,
      translationParam: PropTypes.string
    })
  ),
  onChallengeFactor: PropTypes.func.isRequired
};

export default AccountVerificationOptionsForm;
