import React, { useEffect, useRef, useState } from "react";
import { Trans, Translation } from "react-i18next";
import SubmitButton from "@cx/ui/SubmitButton";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";
import IconError from "@cx/ui/Icons/IconError";
import LinkButton from "../link-button/linkButton";

import "./enrollVerificationForm.scss";

const EnrollVerificationForm = props => {
  const resendTimeout = props.resendTimeout ? props.resendTimeout : 30;
  const timerInitialState = {
    remaining: resendTimeout,
    timestamp: Date.now() + resendTimeout * 1000
  };

  const [code, setCode] = useState("");
  const [isValidCode, setIsValidCode] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isResendDisabled, setResendDisabled] = useState(true);
  const [timer, setTimer] = useState(timerInitialState);
  const intervalRef = useRef(0);
  const factor = props.factor;
  const delaySubmit = factor.factorType === "SMS";

  useEffect(() => {
    if (isResendDisabled) {
      intervalRef.current = setInterval(() => {
        if (timer.remaining <= 1) {
          setResendDisabled(false);
        } else {
          setTimer(timer => ({
            ...timer,
            remaining: Math.ceil((timer.timestamp - Date.now()) / 1000)
          }));
        }
      }, 1000);
    }
    return () => {
      clearInterval(intervalRef.current);
    };
  }, [isResendDisabled, timer]);

  const onCodeInvalid = displayError => {
    setIsValidCode(false);
    if (document.getElementById("input-verification-code")) {
      document.getElementById("input-verification-code").value = "";
    }
    if (displayError) setError("accountVerificationForm.errorCodeInvalid");
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (!loading) {
        if (isValidCode) {
          onClickVerifyButton();
        } else {
          onCodeInvalid(true);
        }
      }
    }
  };

  const onChange = e => {
    setCode(e.target.value);
    setIsValidCode(e.target.value.match(/^\d{6}$/g));
    setError(null);
  };

  const onClickVerifyButton = e => {
    setLoading(true);
    props.onVerifyFactor(factor.factorType, code).then(async response => {
      const success = response === true;
      const resultMessage = success
        ? `Submitted a correct passcode (${factor.factorType})`
        : `Submitted an incorrect or failed passcode (${factor.factorType})`;
      linkClicked(
        "Verify passcode button",
        resultMessage,
        "MFA Enroll Verification Form"
      );

      if (!success) {
        onCodeInvalid(!response);
      } else {
        if (delaySubmit && timer.remaining > 1) {
          await new Promise(resolve =>
            setTimeout(resolve, timer.remaining * 1000)
          );
        }
        props.successCallback();
      }
      setLoading(false);
    });
  };

  const onClickGoBack = e => {
    linkClicked(
      "Try another method button",
      "Return to the MFA enrollment options form",
      "MFA Enroll Verification Form"
    );
    props.returnToEnrollOptions();
  };

  const onClickSendNewCode = e => {
    e.preventDefault();
    props
      .onChallengeFactor(factor.factorType, factor.translationParam)
      .then(success => {
        const resultMessage = success
          ? `Send a new account verification code (${factor.factorType})`
          : `Failed to send a new account verification code (${factor.factorType})`;
        linkClicked(
          `Send new code button`,
          resultMessage,
          "MFA Enroll Verification Form"
        );
        if (success) {
          setResendDisabled(true);
          setTimer(timerInitialState);
        }
      });
  };

  const translationParam = factor.translationParam;
  return (
    <Translation>
      {t => (
        <form id="enrollVerificationForm">
          <div>
            <Trans
              i18nKey={"accountVerificationForm.body"}
              values={{
                codeSentTo: translationParam || null
              }}
            />
            <br />
            <br />

            <div className={error ? "has-error" : ""}>
              <input
                autoFocus
                className="form-control textInput"
                id="input-verification-code"
                name="input-verification-code"
                type="text"
                autoComplete="input-verification-code"
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={t("accountVerificationForm.input")}
              />
            </div>
            {error && (
              <div className="has-error help-block__wrapper">
                <IconError className="svgicon icon-error" />
                <span className="help-block">{t(error)}</span>
              </div>
            )}
            <div id="resend-container">
              <LinkButton
                htmlId={"button-resend-code"}
                onClick={onClickSendNewCode}
                disabled={isResendDisabled || loading}
              >
                {isResendDisabled
                  ? t("accountVerificationForm.countdown", {
                      timeRemaining: timer.remaining
                    })
                  : t("otpValidationForm.resendCode")}
              </LinkButton>
            </div>
            <br />
            <div className="button-group">
              <Button
                htmlId="button-go-back"
                buttonStyle="secondary"
                onClick={onClickGoBack}
                type="button"
                disabled={loading}
              >
                {t("accountVerificationForm.buttonGoBack")}
              </Button>
              <SubmitButton
                disabled={!isValidCode || loading}
                isLoading={loading}
                loadingText={
                  delaySubmit
                    ? t("enrollFactorVerificationForm.buttonLoading", {
                        timeRemaining: timer.remaining
                      })
                    : t("accountVerificationForm.buttonLoading")
                }
                htmlId="button-account-recovery-submit"
                buttonStyle="primary"
                onClick={onClickVerifyButton}
                type="button"
              >
                {t("accountVerificationForm.button")}
              </SubmitButton>
            </div>
          </div>
        </form>
      )}
    </Translation>
  );
};

EnrollVerificationForm.propTypes = {
  factor: PropTypes.shape({
    factorType: PropTypes.string.isRequired,
    translationParam: PropTypes.string
  }),
  onChallengeFactor: PropTypes.func.isRequired,
  onVerifyFactor: PropTypes.func.isRequired,
  resendTimeout: PropTypes.number,
  returnToEnrollOptions: PropTypes.func.isRequired,
  successCallback: PropTypes.func.isRequired
};

export default EnrollVerificationForm;
