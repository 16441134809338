export default function getEnv() {
  const url = window.location.hostname;

  if (url === "signin.dangerzone.coxautoservices.com") {
    return "dangerzone";
  }

  if (url.indexOf("signin.coxautoinc.com") !== -1) {
    return "live";
  }

  if (url === "localhost" || url === "127.0.0.1") {
    return "dangerzone";
  }

  return "unknown";
}

const externalApplications = {
  dangerzone: {
    "user-settings.dangerzone.coxautoservices.com": "My Settings"
  },
  live: {
    "user-settings.coxautoinc.com": "My Settings"
  }
};

export const externalAppName = hostname => {
  return externalApplications[getEnv()][hostname];
};
