import React from "react";
import "./linkButton.scss";
import PropTypes from "prop-types";

/**
 * A button that looks like a link.
 */
const LinkButton = props => (
  <button
    id={props.htmlId}
    className="link-button"
    onClick={props.onClick}
    disabled={props.disabled}
  >
    {props.children}
  </button>
);

LinkButton.propTypes = {
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  htmlId: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

export default LinkButton;
