export const isThirdPartyCookieEnabled = (username, clientId) => {
  const url = "https://v506vqbo50.execute-api.us-east-2.amazonaws.com";
  const requestOptions = {
    method: "GET",
    mode: "cors",
    credentials: "include"
  };

  return fetch(`${url}/setup`, requestOptions)
    .then(response => {
      if (response.status === 200) {
        const params = new URLSearchParams();
        if (username) {
          params.append("username", username);
        }
        if (clientId) {
          params.append("clientid", clientId);
        }
        let testUrl = `${url}/test`;
        if (params.has("username") || params.has("clientid")) {
          testUrl = `${testUrl}?${params.toString()}`;
        }
        return fetch(`${testUrl}`, requestOptions);
      } else {
        throw new Error();
      }
    })
    .then(res => {
      if (res.status === 200) {
        return true;
      } else if (res.status === 400) {
        return false;
      } else {
        return undefined;
      }
    })
    .catch(() => undefined);
};
