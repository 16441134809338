import React from "react";
import { Translation } from "react-i18next";
import SubmitButton from "@cx/ui/SubmitButton";
import PropTypes from "prop-types";
import IconComplete from "@cx/ui/Icons/IconComplete";

import "./successForm.scss";

const SuccessForm = props => {
  const onClickContinue = e => {
    props.onClick();
    e.preventDefault();
  };

  return (
    <Translation>
      {t => (
        <div className="success-form">
          <IconComplete />
          <div>{t(props.message)}</div>
          <SubmitButton
            htmlId="success-form-continue"
            onClick={onClickContinue}
          >
            {t("successForm.continue", {
              solutionDisplayName: props.solutionDisplayName
            })}
          </SubmitButton>
        </div>
      )}
    </Translation>
  );
};

SuccessForm.propTypes = {
  message: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  solutionDisplayName: PropTypes.string.isRequired
};

export default SuccessForm;
