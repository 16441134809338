import React from "react";
import "./cardTemplate.scss";
import PropTypes from "prop-types";
import Card from "@cx/ui/Card";
import ContactUs from "../contact-us/contactUs";

/**
 * Container for an authorization page, centrally positioned in view.
 */
const CardTemplate = props => (
  <div className="card-template">
    <Card
      htmlId="cardWithHeader"
      header={
        <div>
          {props.logo && (
            <div className={"logo"}>
              <img src={props.logo} alt={props.logoAlt} />
              <hr />
            </div>
          )}
          {props.title}
        </div>
      }
    >
      {props.body}
    </Card>
    <div className="card-footer">
      {props.contactUsUrl && <ContactUs contactUsUrl={props.contactUsUrl} />}
    </div>
  </div>
);

CardTemplate.propTypes = {
  body: PropTypes.object.isRequired,
  contactUsUrl: PropTypes.string,
  logo: PropTypes.string,
  logoAlt: PropTypes.string,
  title: PropTypes.object.isRequired
};

export default CardTemplate;
