import React, { useState } from "react";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";
import EmailInput from "@cx/ui/EmailInput";
import SubmitButton from "@cx/ui/SubmitButton";
import Button from "@cx/ui/Button";
import IconError from "@cx/ui/Icons/IconError";

import "./enrollInternationalFactorForm.scss";

import "react-phone-number-input/style.css";
import PhoneInput, {
  formatPhoneNumber,
  isValidPhoneNumber
} from "react-phone-number-input";

const EnrollInternationalFactorForm = props => {
  const factorType = props.factor.factorType;

  const factorInputId = "factor-input";

  const initialState = "";
  const [phoneNumber, setPhoneNumber] = useState(initialState);
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState(false);
  const [email, setEmail] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [errorCode, setErrorCode] = useState("");

  const [loading, setLoading] = useState(false);

  const onClickEnrollButton = e => {
    setLoading(true);
    props
      .onEnrollFactor(
        factorType,
        phoneNumber ? formatPhoneNumber(phoneNumber, "International") : email
      )
      .then(success => {
        const resultMessage = success
          ? `Send an MFA enroll verification code by ${factorType}`
          : `Failed to send an MFA enroll verification code by ${factorType}`;
        linkClicked(
          `Enroll MFA with ${factorType} button`,
          resultMessage,
          "MFA Enroll Form"
        );
        setLoading(false);
      })
      .catch(err => {
        if (err.message.indexOf("emailInUse") !== -1) {
          setErrorCode("inUse");
          setLoading(false);

          linkClicked(
            `Enroll MFA with ${factorType} button`,
            "Couldn't enroll, email is already in use",
            "MFA Enroll Form"
          );
        } else if (err.message.indexOf("phoneInvalid") !== -1) {
          setIsPhoneNumberValid(false);
          setLoading(false);

          linkClicked(
            `Enroll MFA with ${factorType} button`,
            "Couldn't enroll, phone number is invalid",
            "MFA Enroll Form"
          );
        }
      });
  };

  const onClickShowFactorEnrollmentOptions = e => {
    props.onReturnToFactorEnrolmentOptions();
    linkClicked(
      `Return to MFA Enroll Options Button`,
      "Success",
      "MFA Enroll Form"
    );
  };

  const onChangePhoneNumber = value => {
    if (value) {
      setIsPhoneNumberValid(isValidPhoneNumber(value));
    } else {
      setIsPhoneNumberValid(false);
    }
    setPhoneNumber(value);
  };

  const phoneInputKeyDown = event => {
    if (event.key === "Enter") {
      event.preventDefault();
      event.stopPropagation();
      if (isValidPhoneNumber(event.target.value)) {
        onClickEnrollButton(event);
      }
    }
  };

  const onChangeEmail = event => {
    setEmail(event.target.value);
    setIsEmailValid(validateEmail(event.target.value));
  };

  const validateEmail = emailAddress => {
    // Source: http://emailregex.com/
    const regEx = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const test = regEx.test(emailAddress);
    setErrorCode(test ? "" : "invalid");
    return test;
  };

  const onEmailInputBlur = event => {
    setEmail(event.target.value);
    setIsEmailValid(validateEmail(event.target.value));
  };

  const emailInputKeyDown = event => {
    if (event.key === "Enter") {
      onEmailInputBlur(event);
      if (validateEmail(event.target.value)) {
        onClickEnrollButton(event);
      }
    }
  };

  const showPhoneError = !(isPhoneNumberValid || phoneNumber === initialState);
  const showEmailError = !(!errorCode || email === "");

  return (
    <Translation>
      {t => (
        <>
          {factorType === "SMS" && (
            <div className="sms-factor-input" onKeyDown={phoneInputKeyDown}>
              <PhoneInput
                autoFocus
                id={`${factorInputId}-maskedInput`}
                international
                name="phoneNumber"
                label={t("enrollFactorForm.phoneInputLabel")}
                defaultCountry="US"
                value={phoneNumber}
                onChange={onChangePhoneNumber}
              />
              {showPhoneError && (
                <div className="has-error help-block__wrapper">
                  <IconError className="svgicon icon-error" />
                  <span className="help-block">
                    {t("enrollFactorForm.phoneNumberInvalid")}
                  </span>
                </div>
              )}
            </div>
          )}
          {factorType === "EMAIL" && (
            <div className="email-factor-input" onKeyDown={emailInputKeyDown}>
              <EmailInput
                autoFocus
                displayLabel={false}
                htmlId={factorInputId}
                label={t("enrollFactorForm.emailInputLabel")}
                name="email"
                onChange={onChangeEmail}
                onBlur={onEmailInputBlur}
                value={email}
                getError={() => {
                  return showEmailError
                    ? t("enrollFactorForm.emailError." + errorCode)
                    : "";
                }}
              />
            </div>
          )}
          {!(
            (factorType === "SMS" && showPhoneError) ||
            (factorType === "EMAIL" && showEmailError)
          ) && (
            <div id="enrollFactorHelpText">{t("enrollFactorForm.help")}</div>
          )}
          <br />
          <div id="enroll-factor-form-buttons">
            <Button
              className="change-enroll btn"
              htmlId="changeFactorEnrollOptionsBtn"
              onClick={onClickShowFactorEnrollmentOptions}
              buttonStyle="secondary"
              type="button"
              disabled={loading}
            >
              <Trans i18nKey="enrollFactorForm.differentOption" />
            </Button>
            <SubmitButton
              isLoading={loading}
              disabled={
                loading ||
                (factorType === "SMS" ? !isPhoneNumberValid : !isEmailValid)
              }
              loadingText={t("enrollFactorForm.buttonLoading")}
              htmlId="button-enroll-factor-submit"
              buttonStyle="primary"
              onClick={onClickEnrollButton}
              type="button"
            >
              {t("enrollFactorForm.button")}
            </SubmitButton>
          </div>
        </>
      )}
    </Translation>
  );
};

EnrollInternationalFactorForm.propTypes = {
  factor: PropTypes.shape({
    factorType: PropTypes.string.isRequired
  }),
  onEnrollFactor: PropTypes.func.isRequired,
  onReturnToFactorEnrolmentOptions: PropTypes.func.isRequired
};

export default EnrollInternationalFactorForm;
