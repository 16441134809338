import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import TruncateText from "@cx/ui/TruncateText";
import "./disambiguationForm.scss";
import { Translation } from "react-i18next";
import { linkClicked } from "../../api/analytics";
import LinkButton from "../link-button/linkButton";

class DisambiguationForm extends React.Component {
  state = {
    solutionList: this.props.solutionList || []
  };

  onClickUserName = e => {
    linkClicked(
      "Username Back Button",
      "Return to Username Form",
      "Disambiguation Form"
    );
    this.props.onClickUsernameFromDisambiguationPage();
    e.preventDefault();
  };

  onClickSolutionName = (e, value) => {
    e.preventDefault();
    const clickedValue = value.isBridge ? "Bridge" : "Solution";
    linkClicked(
      "Solution Group Select Button : " + clickedValue,
      "Disambiguate user to " + clickedValue,
      "Disambiguation Form"
    );
    this.props.onSubmitSolutionName(value);
  };

  render() {
    let nonBridgeSolutionName = "solution";
    if (this.state.solutionList !== undefined) {
      // checks in the list of solutions whether there is a non bridge solution and returns true if it exists
      const foundNonBridgeSolution = this.state.solutionList.find(
        (value, index) => {
          return value !== undefined && !value.isBridge;
        }
      );

      nonBridgeSolutionName =
        foundNonBridgeSolution === undefined
          ? "solution"
          : foundNonBridgeSolution.displayName;
    }

    return (
      <Translation>
        {t => (
          <div>
            <LinkButton
              htmlId="return-link-disambiguation"
              onClick={this.onClickUserName}
            >
              <TruncateText htmlId={"return-link-disambiguation-text"}>
                &larr; {this.props.username}
              </TruncateText>
            </LinkButton>
            <br />
            <br />

            <span id={"disambiguation-form-text"}>
              {t("disambiguationForm.text", {
                username: this.props.username.toUpperCase(),
                solutionDisplayName:
                  this.state.solutionList === null ||
                  this.state.solutionList === undefined
                    ? t("common.solution")
                    : nonBridgeSolutionName
              })}
            </span>

            <br />
            <br />

            <Grid htmlId="buttonGrid">
              {this.state.solutionList === null ||
              this.state.solutionList === undefined ? (
                <div />
              ) : (
                this.state.solutionList.map((value, index) => {
                  const key = "solButton" + index;
                  return (
                    <Row key={key + "Row"} className="button-row">
                      <Button
                        className="button-item"
                        htmlId={key}
                        block
                        buttonStyle="secondary"
                        onClick={e => this.onClickSolutionName(e, value)}
                      >
                        {value.isBridge
                          ? t("disambiguationForm.buttonSignInBridge", {
                              solutionDisplayName: value.displayName
                            })
                          : t("disambiguationForm.buttonSignInSolution", {
                              solutionDisplayName: value.displayName
                            })}
                      </Button>
                    </Row>
                  );
                })
              )}
            </Grid>
          </div>
        )}
      </Translation>
    );
  }
}

DisambiguationForm.propTypes = {
  onClickUsernameFromDisambiguationPage: PropTypes.func.isRequired,
  onSubmitSolutionName: PropTypes.func.isRequired,
  solutionList: PropTypes.array.isRequired,
  username: PropTypes.string.isRequired
};

export default DisambiguationForm;
