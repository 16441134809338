import React from "react";
import PropTypes from "prop-types";
import SubmitButton from "@cx/ui/SubmitButton";

import { Trans } from "react-i18next";
import { linkClicked } from "../../api/analytics";

class DuplicateSessionForm extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: false
    };
  }

  submitLogout = () => {
    this.setState({ loading: true });
    linkClicked(
      "Logout And Continue",
      "Submitting logout and will submit Signin again in 4 seconds",
      "duplicateSession"
    );

    setTimeout(() => {
      // if successful, keep loading animation going as we are now waiting for a redirect
      // and if unsuccessful stop loading so the user can retry the operation
      this.props
        .retryFunction()
        .then(success => this.setState({ loading: success }));
    }, 4000);
  };

  render() {
    return (
      <div>
        <p>
          <Trans
            i18nKey={"duplicateSession.text"}
            values={{
              sessionUsername: this.props.sessionBridgeUsername.toUpperCase(),
              newUsername: this.props.newUsername.toUpperCase()
            }}
          />
        </p>
        <SubmitButton
          htmlId={"logout-continue"}
          className={"pull-right"}
          isLoading={this.state.loading}
          onClick={this.submitLogout}
        >
          <Trans i18nKey={"duplicateSession.buttonText"} />
        </SubmitButton>
        {this.state.loading && (
          <iframe
            title={"logout handler"}
            src={"/logout?do_redirect=false&bridgeEnv=" + this.props.bridgeEnv}
            style={{ display: "none" }}
          />
        )}
      </div>
    );
  }
}
DuplicateSessionForm.propTypes = {
  bridgeEnv: PropTypes.oneOf(["PROD", "QA", "DEV"]).isRequired,
  newUsername: PropTypes.string.isRequired,
  retryFunction: PropTypes.func.isRequired,
  sessionBridgeUsername: PropTypes.string.isRequired
};
export default DuplicateSessionForm;
