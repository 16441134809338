import React from "react";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import "./thirdPartyAccessDenied.scss";
import { Translation } from "react-i18next";
import { linkClicked } from "../../api/analytics";

class ThirdPartyAccessDenied extends React.Component {
  onTryAgainClick = () => {
    linkClicked(
      "Back To Signin Button",
      "Go back to signin to retry sp initiated 3rd party self-registration",
      "Third Party Access Denied Page"
    );

    const destination = `${this.props.apiBaseUrl}/?partition=${this.props.partition}&clientId=${this.props.clientId}`;
    window.location.assign(destination);
  };

  render() {
    return (
      <Translation>
        {t => (
          <div id={"thirdPartyAccessDenied"}>
            {t("thirdPartyAccessDenied.explanation")}
            <Button
              htmlId={"backToSignInButton"}
              buttonStyle={"primary"}
              onClick={this.onTryAgainClick}
            >
              {t("thirdPartyAccessDenied.backToSignin")}
            </Button>
          </div>
        )}
      </Translation>
    );
  }
}

ThirdPartyAccessDenied.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  partition: PropTypes.string.isRequired
};

export default ThirdPartyAccessDenied;
