import { getI18n } from "react-i18next";

const translationMap = {
  MINIMUM_LENGTH_NOT_MET: "error.MESSAGE_MINIMUM_LENGTH_NOT_MET",
  EXCEEDS_MAXIMUM_LENGTH: "error.MESSAGE_EXCEEDS_MAXIMUM_LENGTH",
  NEED_AT_LEAST_ONE_LETTER: "error.MESSAGE_NEED_AT_LEAST_ONE_LETTER",
  NEED_AT_LEAST_ONE_NUMBER: "error.MESSAGE_NEED_AT_LEAST_ONE_NUMBER",
  NEED_AT_LEAST_ONE_LETTER_OR_ONE_NUMBER:
    "error.MESSAGE_NEED_AT_LEAST_ONE_LETTER_OR_ONE_NUMBER",
  NO_PASSWORD_PROVIDED: "error.MESSAGE_NO_PASSWORD_PROVIDED",
  CANNOT_CONTAIN_USERNAME:
    "error.MESSAGE_CANNOT_CONTAIN_OR_BE_PART_OF_USERNAME",
  CANNOT_BE_PREVIOUSLY_USED_PASSWORD:
    "error.MESSAGE_CANNOT_BE_PREVIOUSLY_USED_PASSWORD",
  CANNOT_BE_A_COMMON_PASSWORD: "error.MESSAGE_CANNOT_BE_A_COMMON_PASSWORD",
  INCORRECT_OLD_PASSWORD: "error.MESSAGE_INCORRECT_OLD_PASSWORD",
  CANNOT_BE_CURRENT_PASSWORD: "error.MESSAGE_CANNOT_BE_CURRENT_PASSWORD",
  CANNOT_BE_DICTIONARY_WORD: "error.MESSAGE_CANNOT_BE_DICTIONARY_WORD",
  CANNOT_BE_COX_BRAND: "error.MESSAGE_CANNOT_BE_COX_BRAND",
  CANNOT_CONTAIN_FIRST_NAME_LAST_NAME:
    "error.MESSAGE_CANNOT_CONTAIN_FIRST_NAME_LAST_NAME",
  CANNOT_CONTAIN_QUADS: "error.MESSAGE_CANNOT_CONTAIN_QUADS",
  PASSWORD_REQUIREMENTS_NOT_MET: "error.MESSAGE_PASSWORD_UNACCEPTABLE",
  DEFAULT: "error.MESSAGE_PASSWORD_UNACCEPTABLE"
};

/**
 * Maps the given User API error code
 * to the associated, translated UI error message.
 * @param {string} userApiCode a User API error code
 * @return {string} the associated, translated UI error message.
 * If userApiCode is invalid, a default password-related error message
 * is returned.
 */
const mapToTranslatedMessage = userApiCode => {
  const translationKey = translationMap[userApiCode]
    ? translationMap[userApiCode]
    : translationMap["DEFAULT"];
  return getI18n().t(translationKey);
};

const isIterable = obj => {
  // checks for null and undefined
  if (obj == null) {
    return false;
  }
  return typeof obj[Symbol.iterator] === "function";
};

/**
 * Maps the given array of User API error codes
 * to an array of the associated, translated UI error messages.
 * @param {Array.<string>} userApiCodes an array of User API error codes
 * @return {Array.<string>} an array of the associated, translated UI error
 * messages. If userApiCodes is undefined, null, not iterable, or empty,
 * an array containing a single default password-related error message is
 * returned.
 */
export const mapToTranslatedMessages = userApiCodes => {
  let codesToMap;

  if (isIterable(userApiCodes) && userApiCodes.length > 0) {
    codesToMap = userApiCodes;
  } else {
    // userApiCodes was not iterable or was empty. This could only happen
    // if something goes wrong upstream (if the User API call
    // fails to populate the array of password error codes.)
    // This function is only called if a password change attempt failed,
    // and we must display some error message to the user, so use the default.
    codesToMap = ["DEFAULT"];
  }

  // remove any duplicates from codesToMap
  codesToMap = [...new Set(codesToMap)];

  let errorMessages = [];
  for (const codeToMap of codesToMap) {
    errorMessages.push(mapToTranslatedMessage(codeToMap));
  }

  // Despite removing duplicates from codesToMap, there still could be
  // duplicates in errorMessages, because the default message occurs for
  // ANY invalid code. Remove duplicates from errorMessages.
  errorMessages = [...new Set(errorMessages)];

  // All messages in errorMessages are now unique, but one default message
  // could still be present. If there is more than one message, remove
  // the DEFAULT message (if it exists).
  if (errorMessages.length > 1) {
    const indexOfDefault = errorMessages.indexOf(
      mapToTranslatedMessage("DEFAULT")
    );
    if (indexOfDefault > -1) {
      // remove the redundant default message
      errorMessages.splice(indexOfDefault, 1);
    }
  }

  return errorMessages;
};
