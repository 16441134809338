import React from "react";
import { Trans } from "react-i18next";
import Button from "@cx/ui/Button";
import "./UserNotLinkedPage.scss";
import PropTypes from "prop-types";
import getEnv from "../env";
import { linkClicked } from "../../api/analytics";

const UserNotLinkedPage = ({ solutionDisplayName, partition }) => {
  const mySettings = {
    dangerzone: {
      PRODUCTION:
        "https://user-settings.dangerzone.coxautoservices.com/#attached-solutions",
      NON_PRODUCTION:
        "https://user-settings.dangerzone.coxautoservices.com/?partition=non-prod#attached-solutions"
    },
    live: {
      PRODUCTION: "https://user-settings.coxautoinc.com/#attached-solutions",
      NON_PRODUCTION:
        "https://user-settings.coxautoinc.com/?partition=non-prod#attached-solutions"
    }
  };

  const landingPage = {
    dangerzone: {
      PRODUCTION: "https://mycoxautomotive.dangerzone.coxautoservices.com/",
      NON_PRODUCTION:
        "https://mycoxautomotive.dangerzone.coxautoservices.com/?partition=non-prod"
    },
    live: {
      PRODUCTION: "https://mycoxautomotive.coxautoinc.com/",
      NON_PRODUCTION:
        "https://mycoxautomotive.coxautoinc.com/?partition=non-prod"
    }
  };

  const mySettingsUrl = mySettings[getEnv()][partition];
  const homePageUrl = landingPage[getEnv()][partition];

  const onClick = element =>
    linkClicked(`Navigate to ${element}`, "Success", "User Not Linked page");

  return (
    <div id="userUnlinkedErrorPage" className={"userUnlinkedErrorPage"}>
      <div className={"redirect-user"}>
        <Button
          block
          id={"attach-solutions-button"}
          href={mySettingsUrl}
          onClick={() => onClick("My Settings - Attach Solutions")}
        >
          <div className={"attach-solution-button-text"}>
            <Trans
              i18nKey={"userUnlinkedForm.mySettingsButton"}
              values={{
                solutionGroup: solutionDisplayName
              }}
            />
          </div>
        </Button>
        <Button
          buttonStyle={"link"}
          id={"landing-page-link"}
          href={homePageUrl}
          onClick={() => onClick("Landing Page")}
        >
          <Trans i18nKey={"userUnlinkedForm.attachedSolutionsLink"} />
        </Button>
      </div>
    </div>
  );
};

UserNotLinkedPage.propTypes = {
  partition: PropTypes.string.isRequired,
  solutionDisplayName: PropTypes.string.isRequired
};

export default UserNotLinkedPage;
