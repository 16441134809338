import React from "react";
import "./Footer.scss";
import { Trans } from "react-i18next";

import Row from "@cx/ui/Row";
import Col from "@cx/ui/Col";
import { linkClicked } from "../../api/analytics";

const Footer = () => (
  <div className="page-footer">
    <Row>
      <Col xs={12} sm={3} className={"terms-of-use-link"}>
        <a
          id="termsOfUseLink"
          href="https://www.coxautoinc.com/terms/wp-content/uploads/sites/3/Common-Customer-TOU.pdf"
          target="_blank"
          rel="noopener noreferrer"
          className="terms-of-use-link"
          onClick={() =>
            linkClicked(
              "Terms of Use Button",
              "Navigate to Terms of Use",
              "Footer"
            )
          }
        >
          <Trans i18nKey="signInControl.termsOfUse" />
        </a>
      </Col>

      <Col xs={12} sm={3} smPush={6} className={"privacy-link"}>
        <a
          id="privacyStatementLink"
          href="https://www.coxautoinc.com/privacy-statement/"
          target="_blank"
          rel="noopener noreferrer"
          className="privacy-link"
          onClick={e =>
            linkClicked(
              "Privacy Statement Button",
              "Navigate to Privacy Statement",
              "Footer"
            )
          }
        >
          <Trans i18nKey="signInControl.privacyStatement" />
        </a>
      </Col>

      <Col xs={12} sm={6} smPull={3} className="copyright">
        <span>&copy;2019 - {new Date().getFullYear()} Cox Automotive.</span>
        <span>
          <Trans i18nKey="signInControl.allRightsReserved" />.
        </span>
      </Col>
    </Row>
  </div>
);

Footer.propTypes = {};

export default Footer;
