import React from "react";
import CardTemplate from "../card/cardTemplate";
import Col from "@cx/ui/Col";
import PropTypes from "prop-types";
import Button from "@cx/ui/Button";
import Tooltip from "@cx/ui/Tooltip";
import { Trans, Translation } from "react-i18next";
import { linkClicked } from "../../api/analytics";
import "./laucherController.scss";

const LauncherControllerForm = props => {
  const solutions = props.launcherOptions.filter(
    item => item["solutionLandingUrl"]
  );
  solutions.sort((a, b) =>
    a.solutionName.toLowerCase() > b.solutionName.toLowerCase() ? 1 : -1
  );

  return (
    <div className="launch-controller">
      <div className="cardContainer">
        <CardTemplate
          title={<Trans i18nKey="launcherControllerForm.title" />}
          body={
            <Translation>
              {t => (
                <div className="launcher-selection-form">
                  {solutions.map(option => (
                    <Col xs={6} md={4} key={option.solutionId}>
                      <Tooltip
                        htmlId={"launch_tooltip_" + option.solutionId}
                        tooltipContent={option.solutionName}
                        position="bottom"
                      >
                        <Button
                          htmlId={"launch_button_" + option.solutionId}
                          buttonStyle="secondary"
                          block
                          className="logoButton"
                          href={option.solutionLandingUrl}
                          onClick={() =>
                            linkClicked(
                              option.solutionName,
                              "Navigate to landing page of " +
                                option.solutionId,
                              "Solution Launcher"
                            )
                          }
                        >
                          <img
                            alt={option.solutionName}
                            src={option.solutionLogoUrl}
                          />
                        </Button>
                      </Tooltip>
                    </Col>
                  ))}
                </div>
              )}
            </Translation>
          }
        />
      </div>
    </div>
  );
};

LauncherControllerForm.propTypes = {
  launcherOptions: PropTypes.arrayOf(
    PropTypes.shape({
      solutionId: PropTypes.string.isRequired,
      solutionName: PropTypes.string.isRequired,
      solutionLandingUrl: PropTypes.string,
      solutionLogoUrl: PropTypes.string
    })
  )
};
export default LauncherControllerForm;
