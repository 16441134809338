import React from "react";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import { Translation } from "react-i18next";

const UsernameRecoveryStatusForm = props => {
  return (
    <Translation>
      {t => (
        <div id="usernameRecoveryStatusForm">
          {t("usernameRecoveryStatusForm.text", {
            recoveryEmail: props.recoveryEmail
          })}
          <br />
          <div className="button-group pull-right">
            <Button
              htmlId="recoveryUsernameStatusBackButton"
              buttonStyle="primary"
              onClick={props.onClickRecoverUsernameStatusBack}
            >
              {t("usernameRecoveryStatusForm.buttonBack")}
            </Button>
          </div>
        </div>
      )}
    </Translation>
  );
};

UsernameRecoveryStatusForm.propTypes = {
  onClickRecoverUsernameStatusBack: PropTypes.func.isRequired,
  recoveryEmail: PropTypes.string.isRequired
};

export default UsernameRecoveryStatusForm;
