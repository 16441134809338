import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "moment/locale/fr";
import "moment/locale/es";

/* eslint-disable import/no-webpack-loader-syntax */
const resources = require("i18next-resource-store-loader!./locales/index.js");
const moment = require("moment");

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .init({
    resources,
    defaultNS: "translation",
    fallbackLng: "en",
    // lng: "fr", // TODO: replace with browser language; for testing locales
    interpolation: {
      escapeValue: false, // react already safes from xss
      format(value, format, lng) {
        if (value instanceof Date)
          return moment(value)
            .locale(lng)
            .format(format);
        return value;
      }
    }
  });

export default i18n;
