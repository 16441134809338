import React, { useState } from "react";
import { Trans, Translation } from "react-i18next";
import SubmitButton from "@cx/ui/SubmitButton";
import Button from "@cx/ui/Button";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";
import IconError from "@cx/ui/Icons/IconError";
import LinkButton from "../link-button/linkButton";

import "./accountVerificationForm.scss";

const AccountVerificationForm = props => {
  const [code, setCode] = useState("");
  const [isValidCode, setIsValidCode] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const getActiveFactor = () => props.factors.filter(f => f.isActive)[0];

  const onCodeInvalid = displayError => {
    if (document.getElementById("input-verification-code")) {
      document.getElementById("input-verification-code").value = "";
    }
    if (displayError) setError("accountVerificationForm.errorCodeInvalid");
  };

  const onKeyDown = e => {
    if (e.key === "Enter") {
      e.preventDefault();
      e.stopPropagation();
      if (isValidCode) {
        onClickVerifyButton();
      } else {
        onCodeInvalid(true);
      }
    }
  };

  const onChange = e => {
    setCode(e.target.value);
    setIsValidCode(e.target.value.match(/^\d{6}$/g));
    setError(null);
  };

  const onClickVerifyButton = e => {
    setLoading(true);
    props.onVerifyFactor(code).then(response => {
      const success = response === true;

      if (!success) onCodeInvalid(!response);
      const resultMessage = success
        ? `Submitted a correct passcode (${getActiveFactor().factorType})`
        : `Submitted an incorrect or failed passcode (${
            getActiveFactor().factorType
          })`;
      linkClicked(
        "Verify passcode button",
        resultMessage,
        "Account Verification Form"
      );
      setLoading(success);
    });
  };

  const onClickGoBack = e => {
    linkClicked(
      "Try another method button",
      "Return to the challenge options form",
      "Account Verification Form"
    );
    props.returnToChallengeOptions();
  };

  const onClickSendNewCode = e => {
    e.preventDefault();
    const factor = getActiveFactor();
    props.onChallengeFactor(factor.id).then(success => {
      const resultMessage = success
        ? `Send a new account verification code (${factor.factorType})`
        : `Failed to send a new account verification code (${factor.factorType})`;
      linkClicked(
        `Send new code button`,
        resultMessage,
        "Account Verification Form"
      );
    });
  };

  return (
    <Translation>
      {t => (
        <form id="accountVerificationForm">
          <div>
            <Trans
              i18nKey={
                "accountVerificationForm.usage." + getActiveFactor().factorType
              }
              values={{
                codeSentTo: getActiveFactor().translationParam || null
              }}
            />
            <br />
            <br />

            <div className={error ? "has-error" : ""}>
              <input
                autoFocus
                className="form-control textInput"
                id="input-verification-code"
                name="input-verification-code"
                type="text"
                autoComplete="input-verification-code"
                onChange={onChange}
                onKeyDown={onKeyDown}
                placeholder={t("accountVerificationForm.input")}
              />
            </div>
            {error && (
              <div className="has-error help-block__wrapper">
                <IconError className="svgicon icon-error" />
                <span className="help-block">{t(error)}</span>
              </div>
            )}
            {getActiveFactor().sendsChallenge && (
              <div id="resend-container">
                <LinkButton
                  htmlId="button-resend-code"
                  onClick={onClickSendNewCode}
                  disabled={loading}
                >
                  {t("otpValidationForm.resendCode")}
                </LinkButton>
              </div>
            )}
            <br />
            <div className="button-group">
              <Button
                htmlId="button-go-back"
                buttonStyle="secondary"
                onClick={onClickGoBack}
                type="button"
                disabled={loading}
              >
                {t("accountVerificationForm.buttonGoBack")}
              </Button>
              <SubmitButton
                disabled={!isValidCode || loading}
                isLoading={loading}
                loadingText={t("accountVerificationForm.buttonLoading")}
                htmlId="button-account-recovery-submit"
                buttonStyle="primary"
                onClick={onClickVerifyButton}
                type="button"
              >
                {t("accountVerificationForm.button")}
              </SubmitButton>
            </div>
          </div>
        </form>
      )}
    </Translation>
  );
};

AccountVerificationForm.propTypes = {
  factors: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      factorType: PropTypes.string.isRequired,
      translationParam: PropTypes.string,
      sendsChallenge: PropTypes.bool,
      isActive: PropTypes.bool
    })
  ),
  onChallengeFactor: PropTypes.func.isRequired,
  onVerifyFactor: PropTypes.func.isRequired,
  returnToChallengeOptions: PropTypes.func.isRequired
};

export default AccountVerificationForm;
