import React from "react";
import PropTypes from "prop-types";
import { Trans } from "react-i18next";
import "./contactUs.scss";
import { linkClicked } from "../../api/analytics";

const ContactUs = props => (
  <div>
    <a
      href={props.contactUsUrl}
      rel="noopener noreferrer"
      target="_blank"
      className="contact-us"
      onClick={() =>
        linkClicked(
          "Contact Us Button",
          "Navigate to Solution's Contact Us Page",
          "Contact Us Form"
        )
      }
    >
      <Trans i18nKey="signInControl.contactUs" />
    </a>
  </div>
);

ContactUs.propTypes = {
  contactUsUrl: PropTypes.string.isRequired
};

export default ContactUs;
