import React, { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import SubmitButton from "@cx/ui/SubmitButton";
import CheckBoxList from "@cx/ui/CheckBoxList";
import { linkClicked } from "../../api/analytics";

import "./eulaForm.scss";

const EulaForm = props => {
  const [checkedAccept, toggleCheckAccept] = useState(false);
  const [loading, setLoading] = useState(false);
  const [t] = useTranslation();

  const onClickContinue = e => {
    e.preventDefault();
    if (loading) {
      // If we are currently loading, no-op
      return;
    }
    setLoading(true);

    linkClicked(
      "Accept EULA",
      "Continuing to Signin after accepting EULA",
      "Eula Form"
    );
    props.onAcceptEula().then(status => {
      // If we are successful it will still be "true" and keep us loading as we are now waiting on the signin call
      // to complete the process.
      setLoading(status);
    });
  };

  return (
    <div>
      <Trans
        i18nKey="eulaForm.body"
        components={{
          termsOfServiceLink: (
            // Text will be injected by translation so disable lint rule below
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={
                "https://www.coxautoinc.com/terms/wp-content/uploads/sites/3/Common-Customer-TOU.pdf"
              }
              target="_blank"
              rel="noopener noreferrer"
              onClick={e =>
                linkClicked(
                  "Terms of Use",
                  "Navigate to Terms of Use",
                  "Eula Form"
                )
              }
            />
          ),
          privacyPolicyLink: (
            // Text will be injected by translation so disable lint rule below
            // eslint-disable-next-line jsx-a11y/anchor-has-content
            <a
              href={"https://www.coxautoinc.com/privacy-statement/"}
              target="_blank"
              rel="noopener noreferrer"
              onClick={e =>
                linkClicked(
                  "Privacy Statement",
                  "Navigate to Privacy Statement",
                  "Eula Form"
                )
              }
            />
          )
        }}
      />
      <CheckBoxList
        htmlId="AcceptEula"
        name="AcceptEula"
        onChange={() => toggleCheckAccept(!checkedAccept)}
        options={[{ value: "accept", label: t("eulaForm.checkBox") }]}
      />
      <SubmitButton
        htmlId="acceptEula"
        onClick={onClickContinue}
        disabled={!checkedAccept}
        isLoading={loading}
      >
        {t("eulaForm.acceptButton")}
      </SubmitButton>
    </div>
  );
};

EulaForm.propTypes = {
  onAcceptEula: PropTypes.func.isRequired
};

export default EulaForm;
