import React, { Component } from "react";
import LauncherControlerForm from "../launchercontrol-form/launcherControllerForm";
import Header from "../header/Header";
import PropTypes from "prop-types";
import "../app.scss";
import Footer from "../footer/Footer";
import { initializeDataLayer } from "../../api/analytics";

class SolutionLauncher extends Component {
  componentDidMount() {
    initializeDataLayer("");
  }

  render() {
    return (
      <div className="app-grid">
        <div className={"app-header"}>
          <Header />
        </div>
        <div className="app-content">
          <LauncherControlerForm
            launcherOptions={JSON.parse(this.props.launcherOptions)}
          />
        </div>
        <Footer />
      </div>
    );
  }
}

SolutionLauncher.propTypes = {
  launcherOptions: PropTypes.string
};

export default SolutionLauncher;
