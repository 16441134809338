import React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@cx/ui/internal/SvgIcon";
import "./IconCircle.scss";

const IconCircle = ({ htmlId, className, isActive, ...other }) => {
  return (
    <SvgIcon
      id={htmlId}
      viewBox="0 0 24 24"
      className={`svgicon ${className ? `${className} ` : ""}${
        isActive === true ? "svgicon--active " : ""
      }icon-circle`}
      {...other}
    >
      <circle cx="50%" cy="50%" r="12" />
    </SvgIcon>
  );
};

IconCircle.propTypes = {
  className: PropTypes.string,
  htmlId: PropTypes.string,
  isActive: PropTypes.bool
};

export default IconCircle;
