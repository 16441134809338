import React from "react";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";
import FormGroup from "react-bootstrap/lib/FormGroup";
import Button from "@cx/ui/Button";
import Card from "@cx/ui/Card";
import IconCircle from "../icons/IconCircle";
import IconStack from "../icons/IconStack";
import IconSms from "@cx/ui/Icons/IconTextsms";
import IconEmail from "@cx/ui/Icons/IconEmail";
import "./factorEnrollmentOptionsForm.scss";

const FactorEnrollmentOptionsForm = props => {
  const defaultFactors = ["SMS", "EMAIL"];
  const onClickEnrollButton = (e, factor) => {
    props.onEnrollFactor(factor);
    linkClicked(
      `Enroll ${factor} factor button`,
      "User successfully taken to enroll factor form",
      "Factor Enrollment Form"
    );
  };

  return (
    <Translation>
      {t => (
        <FormGroup
          className="factorEnrollmentOptionsForm"
          controlId="factorEnrollmentOptionsForm"
        >
          <div>
            {t(
              `factorEnrollmentOptionsForm.body${
                props.forRecovery ? "Recovery" : ""
              }`
            )}
          </div>
          <div className="factorEnrolmentHelpText">
            <a
              target="_blank"
              href="https://www.coxautoinc.com/bridgeid"
              rel="noopener noreferrer"
              onClick={e =>
                linkClicked(
                  "Help",
                  "Navigate to Help",
                  "Factor Enrollment Form"
                )
              }
            >
              {t("factorEnrollmentOptionsForm.help")} &rarr;
            </a>
          </div>
          <div className="factorEnrolmentOptionHeader">
            <strong>
              {t(
                `factorEnrollmentOptionsForm.optionHeader${
                  props.forRecovery ? "Recovery" : ""
                }`
              )}
            </strong>
          </div>
          {defaultFactors.map(option => {
            const lcOption = option.toLowerCase();
            const IconFactor = lcOption === "sms" ? IconSms : IconEmail;
            const disclaimerClassName =
              lcOption === "sms"
                ? "factorEnrollmentShowDisclaimerLabel"
                : "factorEnrollmentHideDisclaimerLabel";
            return (
              <Card key={lcOption} className="factor-card">
                <IconStack>
                  <IconCircle isActive={true} />
                  <IconFactor
                    className="stacked-icon circled-icon"
                    isActive={true}
                  />
                </IconStack>
                <div className="factorEnrollCardText">
                  {
                    <Trans
                      htmlFor={"enrolment-option-button-" + lcOption}
                      i18nKey={"factorEnrollmentOptionsForm." + lcOption}
                      components={{
                        disclaimer: (
                          // Text will be injected by translation so disable lint rule below
                          // eslint-disable-next-line jsx-a11y/anchor-has-content
                          <div className={disclaimerClassName} />
                        )
                      }}
                    />
                  }
                </div>
                <span className="button-enroll">
                  <Button
                    htmlId={"button-enroll-by-" + lcOption}
                    size="small"
                    onClick={e => onClickEnrollButton(e, option)}
                  >
                    {t("factorEnrollmentOptionsForm.select")}
                  </Button>
                </span>
              </Card>
            );
          })}
        </FormGroup>
      )}
    </Translation>
  );
};

FactorEnrollmentOptionsForm.propTypes = {
  forRecovery: PropTypes.bool,
  onEnrollFactor: PropTypes.func.isRequired
};

export default FactorEnrollmentOptionsForm;
