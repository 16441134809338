import React from "react";
import Button from "@cx/ui/Button";
import Grid from "@cx/ui/Grid";
import Row from "@cx/ui/Row";
import PropTypes from "prop-types";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import { Translation } from "react-i18next";
import { linkClicked } from "../../api/analytics";
import { withRouter } from "react-router-dom";

/**
 * Username Recovery Page
 */
class UsernameRecoveryForm extends React.Component {
  state = {
    loading: true,
    availableSolutions: []
  };

  componentDidMount() {
    this.fetchAvailableSolutions();
  }

  // Processes click of Bridge or solution button for recover username.
  onClickSolutionName = (e, value) => {
    const clickedSolutionValue = value.isBridge ? "Bridge" : "Solution";
    linkClicked(
      "Solution Group Select Button : " + clickedSolutionValue,
      "Attempted Navigation to " +
        clickedSolutionValue +
        " username reset page",
      "Username Recovery Form"
    );
    // Do Bridge or solution-specific navigation for recovering username.
    this.navigationHandler(value, false);
  };

  navigationHandler = (idp, shortCircuit) => {
    if (idp.isBridge) {
      this.props.recoverBridgeUsername(shortCircuit);
    } else {
      window.location.assign(idp.usernameResetUrl);
    }
  };

  fetchAvailableSolutions = () => {
    this.props
      .fetchIdentityProviders({
        solutionId: this.props.solutionId,
        /* eslint-disable-next-line camelcase */
        client_id: this.props.clientId,
        flag: "forgotUsername"
      })
      .then(
        result => {
          // there are 2 items that come back, if both usernameResetUrl's are null,
          // go to recover bridge username
          if (result.items.filter(elem => elem.usernameResetUrl).length === 0) {
            this.navigationHandler({ isBridge: true }, true);
          } else {
            this.setState({
              availableSolutions: result.items,
              loading: false
            });
          }
        },
        error => {
          this.setState({
            errorMsg: "error.generic"
          });
        }
      )
      .catch(err => {});
  };

  render() {
    if (this.state.loading) {
      return <LoadingIndicator htmlId="LoadingIndicatorLarge" size="large" />;
    } else {
      return (
        <Translation>
          {t => (
            <div>
              {t("usernameRecoveryForm.text", {
                solutionDisplayName: "" + this.props.solutionDisplayName
              })}
              <br />
              <br />
              <Grid htmlId="recoverUsernameButtonGrid">
                {this.state.availableSolutions.map((value, index) => {
                  const key = "solutionButton" + index;
                  return (
                    <Row key={key + "Row"} className="button-row">
                      <Button
                        className="button-item"
                        buttonStyle="secondary"
                        htmlId={key}
                        block
                        onClick={e => this.onClickSolutionName(e, value)}
                      >
                        {" "}
                        {value.isBridge
                          ? t("usernameRecoveryForm.buttonBridge")
                          : t("usernameRecoveryForm.buttonSolution", {
                              solutionDisplayName:
                                "" + this.props.solutionDisplayName
                            })}
                      </Button>
                    </Row>
                  );
                })}
              </Grid>
            </div>
          )}
        </Translation>
      );
    }
  }
}

UsernameRecoveryForm.propTypes = {
  apiBaseUrl: PropTypes.string.isRequired,
  clientId: PropTypes.string.isRequired,
  featureFlags: PropTypes.object.isRequired,
  fetchIdentityProviders: PropTypes.func.isRequired,
  recoverBridgeUsername: PropTypes.func.isRequired,
  solutionDisplayName: PropTypes.string.isRequired,
  solutionId: PropTypes.string.isRequired
};

export default withRouter(UsernameRecoveryForm);
