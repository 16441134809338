import React from "react";
import Button from "@cx/ui/Button";
import { Trans, Translation } from "react-i18next";
import PropTypes from "prop-types";
import { linkClicked } from "../../api/analytics";

// TODO: Convert to stateless component
// TODO: Discuss moving GA out of individual components to keep them simple
export default class PasswordRecoveryOptionsForm extends React.Component {
  onClickRecoveryBySmsButton = () => {
    linkClicked(
      "Verify by SMS Code",
      "Initiate SMS Password Recovery",
      "Password Recovery Options Form"
    );
    this.props.onClickPasswordRecoveryBySmsFromOptionsForm();
  };

  onClickRecoveryByEmailButton = () => {
    linkClicked(
      "Verify by Email",
      "Initiate Email Password Recovery",
      "Password Recovery Options Form"
    );
    this.props.onClickPasswordRecoveryByEmailFromOptionsForm();
  };

  render() {
    return (
      <Translation>
        {t => (
          <form id="passwordRecoveryOptionsForm">
            <div>
              <Trans i18nKey="passwordRecoveryOptionsForm.text" />
              <br />
              <br />
            </div>
            <Button
              htmlId="RecoveryByEmailOption"
              onClick={this.onClickRecoveryByEmailButton}
              buttonStyle="secondary"
              block={true}
              type="button"
            >
              {t("passwordRecoveryOptionsForm.emailButton")}
            </Button>
            <Button
              htmlId="RecoveryBySmsOption"
              onClick={this.onClickRecoveryBySmsButton}
              buttonStyle="secondary"
              block={true}
              type="button"
            >
              {t("passwordRecoveryOptionsForm.smsButton")}
            </Button>
          </form>
        )}
      </Translation>
    );
  }
}

PasswordRecoveryOptionsForm.propTypes = {
  onClickPasswordRecoveryByEmailFromOptionsForm: PropTypes.func.isRequired,
  onClickPasswordRecoveryBySmsFromOptionsForm: PropTypes.func.isRequired
};
